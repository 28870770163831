import styled from 'styled-components';
import ShareLink from 'components/ShareLink/style';
import { media } from 'styled-bootstrap-grid';

export const Title = styled.h2`
  font-size: 1.4rem;

  ${media.lg`
    font-size: 2.4rem;
  `}
`;

export const ShareLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${ShareLink} {
    margin-left: 0 !important;
    margin-top: 1.2rem;

    ${media.lg`
      margin-top: 2.7rem;
    `}
  }
`;

export const Logo = styled.img`
  max-height: 22rem;
  max-width: 100%;

  ${media.lg`
    max-width: 100%;
  `}
`;

const Root = styled.div`
  background: #121212;
  border-radius: 4rem;
  width: 100%;
  padding: 3rem;
  margin-bottom: 2rem;
`;

export default Root;
