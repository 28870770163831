import React, { useState, useEffect, useRef } from 'react';
import Root, { Title, MiniTitle, Message, MessageCursor } from './style';

const messages = ['coming soon', 'coming BIG', 'coming to change the unfair world order'];
let messageIndex = 0;
let charIndex = 0;

const Spotlight = () => {
  const [message, setMessage] = useState(messages[0]);
  const timeoutRef = useRef(null);

  // Manage text animation
  useEffect(() => {
    function stepMessageAnimation() {
      const currentMessage = messages[messageIndex];
      const messageLength = currentMessage.length;

      setMessage(currentMessage.substr(0, charIndex));

      charIndex += 1;

      if (charIndex > messageLength) {
        charIndex = 0;
        messageIndex += 1;

        if (messageIndex >= messages.length) {
          messageIndex = 0;
        }
        timeoutRef.current = setTimeout(stepMessageAnimation, 1000);
      } else {
        timeoutRef.current = setTimeout(stepMessageAnimation, 100);
      }
    }

    clearTimeout(timeoutRef.current);
    stepMessageAnimation();
  }, []);

  return (
    <Root className="container">
      <MiniTitle>“World’s Biggest Help Movement”</MiniTitle>
      <Title dangerouslySetInnerHTML={{ __html: `We<span>C</span>are` }} />
      <Message>
        {message}
        <MessageCursor />
      </Message>
    </Root>
  );
};

export default Spotlight;
