import React from 'react';
import twitter from 'images/twitter.svg';
import telegram from 'images/telegram.svg';
import { TWITTER_LINK, TELEGRAM_LINK } from 'utils/constants';
import ShareLink from 'components/ShareLink/ShareLink';
import logoIcon from 'images/logo-icon.svg';
import Root, { Title, JoinLinksWrapper } from './style';

const Footer = () => {
  return (
    <Root>
      <Title>
        <img src={logoIcon} alt="WeCare" />
        <span>Because We Care...</span>
      </Title>
      <JoinLinksWrapper>
        <ShareLink href={TELEGRAM_LINK}>
          <img src={telegram} alt="Telegram" /> WeCareCryptoCharity
        </ShareLink>
        <ShareLink href={TWITTER_LINK}>
          <img src={twitter} alt="Twitter" /> WeCareCrypto
        </ShareLink>
      </JoinLinksWrapper>
    </Root>
  );
};

export default Footer;
