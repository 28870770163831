import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const Root = styled.a`
  display: inline-flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  transition: color 0.3s;
  font-size: 1rem;

  img {
    margin-right: 0.5rem;
    width: 1.8rem;
  }

  + a {
    margin-left: 2rem;
  }

  &:hover {
    color: #fff;
  }

  ${media.lg`
    font-size: 1.6rem;
    
    img {
      margin-right: 2rem;
    }
  
    + a {
      margin-left: 7.5rem;
    }
  `}
`;

export default Root;
