import styled, { css } from 'styled-components';
import { media } from 'styled-bootstrap-grid';

const Root = styled.div`
  max-width: 100%;
  display: table;
  margin: 3.5rem auto 0 auto;

  ${media.md`
    width: 100%;
    max-width: 53rem;
    margin-top: 10rem;
  `}

  ${media.lg`
    max-width: 78.3rem;
  `}
`;

export const FormWrapper = styled.div`
  display: flex;
  max-width: 100%;
  border: 0.2rem solid #13da75;
  border-radius: 3.6rem;
  padding: 1rem;
`;

export const EmailInput = styled.input`
  border: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background: transparent;
  height: 5.2rem;
  flex-grow: 1;
  min-width: 0;
  box-sizing: border-box;
  color: #fff;
  padding: 0 1.5rem;
  letter-spacing: -0.09px;
  font-weight: 400;
  font-size: 1.6rem;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fff;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #fff;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
  }
`;

export const Button = styled.input`
  display: block;
  width: 13.4rem;
  height: 5.2rem;
  cursor: pointer;
  border: 0;
  padding: 0 21px;
  line-height: 5.2rem;
  background: linear-gradient(270deg, #13da75 0%, #0096ff 100%);
  border-radius: 7.2rem;
  color: #000;
  font-size: 1.6rem;
  font-weight: bold;
`;

export const Message = styled.div`
  font-size: 1.6rem;
  padding: 0.5rem 1.5rem;
  margin-top: 0.5rem;
  opacity: 0;

  ${({ error }) =>
    error
      ? css`
          color: #f00;
        `
      : css`
          color: #13da75;
        `}

  &.enter {
    display: block;
  }
  &.enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  &.enter-done {
    display: block;
    opacity: 1;
  }

  &.exit {
    display: block;
    opacity: 1;
  }
  &.exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  &.exit-done {
    display: none;
  }
`;

export default Root;
