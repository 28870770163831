import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import Root, { FormWrapper, EmailInput, Button, Message } from './style';

const SubscribeButton = () => {
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(false);
  const [error, setError] = useState(false);
  const [canValidate, setCanValidate] = useState(false);

  const validateEmail = useCallback(
    email => {
      if (!email) {
        setError(
          intl.formatMessage({
            id: 'requiredField',
            defaultMessage: '* Required field.',
          })
        );
        return false;
      }

      if (!email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
        setError('* Invalid e-mail.');

        return false;
      }

      setError(null);

      return true;
    },
    [intl]
  );

  const handleEmailChange = useCallback(
    e => {
      setEmail(e.target.value);
      setMessage('');

      if (canValidate) {
        validateEmail(e.target.value);
      }
    },
    [canValidate, validateEmail]
  );

  const handleSubmit = useCallback(
    e => {
      setCanValidate(true);

      const isValid = validateEmail(email);

      if (!isValid) {
        e.preventDefault();

        return false;
      }

      setMessage('Thank you for subscribing.');
    },
    [email, validateEmail]
  );

  /* eslint-disable max-len */
  return (
    <Root id="subscribe">
      <iframe
        title={'Subscribe Now'}
        id={'subscribeFrame'}
        name={'subscribeFrame'}
        style={{ display: 'none' }}
      />
      <form
        action="https://gmail.us6.list-manage.com/subscribe/post?u=571c4a51e3bbb12138c749f99&amp;id=4e8d1eb707"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="subscribeFrame"
        noValidate
        onSubmit={handleSubmit}
      >
        <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
          <input type="text" name="b_571c4a51e3bbb12138c749f99_4e8d1eb707" tabIndex="-1" value="" />
        </div>

        <FormWrapper id="mc_embed_signup">
          <EmailInput
            type="email"
            name="EMAIL"
            className="required email"
            id="mce-EMAIL"
            placeholder={intl.formatMessage({
              id: 'emailAddress',
              defaultMessage: 'Email Address',
            })}
            onChange={handleEmailChange}
            value={email}
          />
          <Button
            type="submit"
            value="SEND"
            name="subscribe"
            id="mc-embedded-subscribe"
            className="button"
          />
        </FormWrapper>
      </form>
      <CSSTransition timeout={300} in={error} className={'transition'}>
        <Message error>{error}</Message>
      </CSSTransition>

      <CSSTransition timeout={300} in={message} className={'transition'}>
        <Message>{message}</Message>
      </CSSTransition>
    </Root>
  );
};

export default SubscribeButton;
