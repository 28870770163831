import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const Title = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 500;
  margin-bottom: 2rem;

  img {
    width: 3rem;
  }

  span {
    margin-left: 1.2rem;
  }

  ${media.md`
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 6rem;
    
    img {
      width: 5rem;
    }
  `}
`;

export const JoinLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;

  ${media.md`
    padding-bottom: 6rem;
  `}
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  width: 100%;
  margin: 4rem 0 0 0;
`;

export default Root;
