export const COOKIE_KEYS = {
  LANGUAGE: `_wecare.language`,
};

export const AVAILABLE_LANGUAGES = {
  en: 'English',
  tr: 'Türkçe',
};

export const TWITTER_LINK = 'https://twitter.com/WeCareCrypto';

export const TELEGRAM_LINK = 'https://t.me/WeCareCryptoCharity';
