import React from 'react';
import PropTypes from 'prop-types';
import Root from './style';

const ShareLink = props => {
  const { href, children, target, rel } = props;

  return (
    <Root href={href} target={target} rel={rel}>
      {children}
    </Root>
  );
};

ShareLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
};

ShareLink.defaultProps = {
  target: '_blank',
  rel: 'noreferrer',
};

export default ShareLink;
