import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

export const LogoLink = styled.a``;

export const LogoImage = styled.img`
  width: 14rem;

  ${media.md`
    width: 19.8rem;
  `}
`;

export const SubscribeLink = styled.a`
  border: 0.2rem solid #13da75;
  border-radius: 2.8rem;
  height: 4rem;
  padding: 1rem 1.5rem;
  font-size: 1.6rem;
  color: #fff;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:active {
    color: #fff;
  }

  ${media.md`
    border-size: 0.1rem;
    height: 5.2rem;
    font-weight: bold;
    padding: 1rem 2rem;
  `}
`;

const Root = styled.div`
  padding-top: 3rem;
  display: flex;
  justify-content: space-between;
`;

export default Root;
