import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import earth from 'images/earth.gif';
// import earth from 'images/earth.jpg';
// import earth2x from 'images/earth@2x.jpg';

export const MiniTitle = styled.h3`
  font-size: 1.6rem;
  line-height: 100%;
  font-weight: 500;

  ${media.md`
    font-size: 2.1rem;
    line-height: 2.7rem;
  `}
`;

export const Title = styled.h1`
  font-size: 6rem;
  font-weight: bold;
  line-height: 10rem;

  span {
    color: #333;
  }

  ${media.md`
    font-size: 12rem;
    line-height: 14.4rem;
  `}
`;

export const Message = styled.p`
  background: #13da75;
  color: #000;
  font-size: 2.2rem;
  line-height: 3rem;
  display: inline-block;
  padding: 0 0 0 1.2rem;
  margin: 0;
  position: relative;

  ${media.md`
    font-size: 4.8rem;
    line-height: 7.2rem;
  `}
`;

export const MessageCursor = styled.span`
  width: 1rem;
  height: 2.2rem;
  display: inline-block;
  position: relative;
  left: 1rem;
  opacity: 0.8;
  animation: pulse 0.5s infinite;
  animation-direction: alternate-reverse;

  @keyframes pulse {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    width: 0.6rem;
    height: 3rem;
    display: block;
    background: #0092f8;
  }

  ${media.md`
    height: 5.2rem;
    
    &:before {
      width: 1rem;
      height: 7.2rem;
    }
  `}
`;

const Root = styled.div`
  margin-top: 3.5rem;
  padding-top: 1rem;
  min-height: 24rem;

  ${media.md`
    height: 54rem;
    padding-top: 10rem;
    background-image: url(${earth});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  `}
`;

export default Root;
