import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import i18n from 'i18n';
import { ThemeProvider } from 'styled-components';
import getClientLanguage from 'utils/getClientLanguage';
import theme from 'theme';
import amandaLogo from 'images/amanda-logo.png';
import palestineLogo from 'images/free-palestine.png';
import Header from 'components/Header';
import Spotlight from 'components/Spotlight';
import Footer from 'components/Footer';
import Token from 'components/Token';
import SubscribeButton from 'components/SubscribeButton';
import Root, { Global } from './style';

const clientLanguage = getClientLanguage();

function App() {
  // eslint-disable-next-line no-unused-vars
  const [language, setLanguage] = useState(clientLanguage);

  return (
    <IntlProvider locale={language} messages={i18n[language]}>
      <ThemeProvider theme={theme}>
        <Global />
        <Root>
          <Header />
          <Spotlight />
          <div className="container" style={{ marginTop: '2rem' }}>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <Token
                  name="Amanda Cerny Fan Token"
                  logo={amandaLogo}
                  websiteLink="https://amandacerny.fan"
                  websiteLinkName="amandacerny.fan"
                  telegramLink="https://t.me/AmandaCernyFanToken"
                  telegramLinkName="AmandaCernyFanToken"
                  twitterLink="https://twitter.com/AmandaFanToken"
                  twitterLinkName="AmandaFanToken"
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <Token
                  name="Free-Palestine"
                  logo={palestineLogo}
                  websiteLink="https://free-palestine.org/"
                  websiteLinkName="free-palestine.org"
                  telegramLink="https://t.me/palestinetoken"
                  telegramLinkName="palestinetoken"
                  twitterLink="https://twitter.com/palestinetoken"
                  twitterLinkName="palestinetoken"
                />
              </div>
            </div>
          </div>
          <SubscribeButton />
          <Footer />
        </Root>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
