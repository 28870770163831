import React from 'react';
import PropTypes from 'prop-types';
import ShareLink from 'components/ShareLink/ShareLink';
import link from 'images/link.svg';
import telegram from 'images/telegram.svg';
import twitter from 'images/twitter.svg';
import Root, { Title, ShareLinksWrapper, Logo } from './style';

const Token = props => {
  const {
    name,
    logo,
    websiteLink,
    websiteLinkName,
    telegramLink,
    telegramLinkName,
    twitterLink,
    twitterLinkName,
  } = props;

  return (
    <Root>
      <Title>{name}</Title>
      <div className="row">
        <div className="col-7">
          <ShareLinksWrapper>
            {websiteLink && websiteLinkName && (
              <ShareLink href={websiteLink}>
                <img src={link} alt={websiteLinkName} />
                {websiteLinkName}
              </ShareLink>
            )}
            {telegramLink && telegramLinkName && (
              <ShareLink href={telegramLink}>
                <img src={telegram} alt={telegramLinkName} />
                {telegramLinkName}
              </ShareLink>
            )}
            {twitterLink && twitterLinkName && (
              <ShareLink href={twitterLink}>
                <img src={twitter} alt={telegramLinkName} />
                {twitterLinkName}
              </ShareLink>
            )}
          </ShareLinksWrapper>
        </div>
        <div className="col-5">{logo && <Logo src={logo} />}</div>
      </div>
    </Root>
  );
};

Token.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string,
  websiteLink: PropTypes.string,
  websiteLinkName: PropTypes.string,
  telegramLink: PropTypes.string,
  telegramLinkName: PropTypes.string,
  twitterLink: PropTypes.string,
  twitterLinkName: PropTypes.string,
};

Token.defaultProps = {
  logo: null,
  websiteLink: null,
  websiteLinkName: null,
  telegramLink: null,
  telegramLinkName: null,
  twitterLink: null,
  twitterLinkName: null,
};

export default Token;
