import { addLocaleData } from 'react-intl';
import enLocale from 'react-intl/locale-data/en';
import trLocale from 'react-intl/locale-data/tr';

import tr from './tr';
import en from './en';

addLocaleData([...trLocale, ...enLocale]);

export default {
  tr,
  en,
};
