import React from 'react';
import Root, { LogoLink, LogoImage, SubscribeLink } from './style';
import logo from 'images/logo.svg';

const Header = () => {
  return (
    <Root className="container">
      <LogoLink href="/">
        <LogoImage src={logo} width={198} />
      </LogoLink>
      <SubscribeLink href="#subscribe">Subscribe WeCare</SubscribeLink>
    </Root>
  );
};

export default Header;
