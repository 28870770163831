import styled, { createGlobalStyle } from 'styled-components';

export const Global = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    
    &:focus {
      outline: none;
    }
  }

  body {
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    
    color: ${({ theme }) => theme.primaryText};
    background: ${({ theme }) => theme.bodyBg};
  }
`;

const Root = styled.div`
  position: relative;
  min-height: 100vh;
`;

export default Root;
