import cookies from 'js-cookie';
import { COOKIE_KEYS, AVAILABLE_LANGUAGES } from './constants';

export default function getClientLanguage() {
  const availableLanguages = Object.keys(AVAILABLE_LANGUAGES);
  const selectedLanguage = cookies.get(COOKIE_KEYS.LANGUAGE);

  let browserLanguage = window?.navigator?.language?.substr(0, 2);

  if (availableLanguages.indexOf(browserLanguage) < 0) {
    browserLanguage = availableLanguages[0].trim().substr(0, 2);
  }

  return selectedLanguage || browserLanguage;
}
